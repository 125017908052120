<template>
  <FocusTrap>
    <div class="card" id="mycard" >


      <ReportViewer v-if="view_report" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->


      <FocusTrap>
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">

              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Stock Filters</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" type="date" class="form-control"  v-model="from_date"/>
                  </div>

                  <label class="col-md-3 col-form-label">Upto:</label>
                  <div class="col-md-3">
                    <input id= "txtupto" type="date" class="form-control"  v-model="upto_date"/>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Group</label>
                  <div class="col-md-9">
                    <select ref="el_bag" class="form-control"  v-model="group_cd">
                      <option value="all">All</option>
                      <option value="Colors">Colors</option>
                      <option value="HDPE">HDPE</option>
                      <option value="MasterBatch">Master Batch</option>
                      <option value="Pigment">Pigment</option>
                      <option value="UV">UV</option>
                      <option value="Zinc">Zinc</option>
                    </select>
                  </div>
                </div>

                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Report<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'


export default {
  name: "rawmaterial_stock_report",
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'rawmaterialstock.mrt',
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      group_cd: 'all',
      view_report: false,
      jsondata : {},
    }
  },
  mounted() {
    const self = this;

    self.$modal.show('filter-window');

  },
  methods: {
    afterfilterwindow_visible() {
      this.$data.reppath = '';
      $('#txtfrom').focus();
    },
    showFilterWindow() {
      this.$modal.show('filter-window');
    },
    loadData(){
      const self = this;

      self.$modal.hide('filter-window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.view_report = false;

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // alert(`${process.env.VUE_APP_ROOT_API}api/report/stock/rawmaterial?cmp_id=${self.$store.state.user.company.id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&group=${self.$data.group_cd}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/report/stock/rawmaterial?cmp_id=${self.$store.state.user.company.id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&group=${self.$data.group_cd}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        $('#mycard').unblock();
        if(resp.ok) {
          self.$data.filename = 'rawmaterialstock.mrt';
          self.$data.jsondata = resp;
          self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/report/stock/rawmaterial?from=${self.$data.from_date}&upto=${self.$data.upto_date}&group=${self.$data.group_cd}`;
          self.$data.view_report = true;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        $('#mycard').unblock();
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });


    },
  }

}
</script>

<style scoped>

</style>
